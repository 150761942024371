@use "sass:meta" as ---mryaz31e5zm;/*
 * Copyright Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "./common";

@include font-face($font-family: "Hack",
$file-path: "~hack-font/build/web/fonts/hack-regular",
$file-formats: ("woff2", "woff")) {
  font-weight: 400;
  font-style: normal;
}

@include font-face($font-family: "Hack",
$file-path: "~hack-font/build/web/fonts/hack-bold",
$file-formats: ("woff2", "woff")) {
  font-weight: 700;
  font-style: normal;
}

@include font-face($font-family: "Hack",
$file-path: "~hack-font/build/web/fonts/hack-italic",
$file-formats: ("woff2", "woff")) {
  font-weight: 400;
  font-style: italic;
}

@include font-face($font-family: "Hack",
$file-path: "~hack-font/build/web/fonts/hack-bolditalic",
$file-formats: ("woff2", "woff")) {
  font-weight: 700;
  font-style: italic;
}

;@include ---mryaz31e5zm.load-css("sass-embedded-legacy-load-done:4950");