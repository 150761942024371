/*
 * Copyright Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use "sass:map";
@import "../../global/common";

$header-font-size: 17px;
$header-font-weight: 600;
$main-nav-height: 40px;
$page-header-height: 50px;
$page-item-padding: 30px;

.page-header {
  width: 100%;
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  place-content: center space-between;
  align-items: center;
  box-sizing: border-box;
  background: $page-header-bg;
  top: $main-nav-height;
  height: $page-header-height;
  padding-left: $page-item-padding;
  padding-right: $page-item-padding;
  z-index: map.get($zindex, "page-header");
  box-shadow: 0 0 10px $box-shadow-color;
}

.section-wrapper {
  display: flex;
  align-self: center;

  &::after {
    content: "|";
    color: $line-color;
    margin: 0 10px;
  }
}

.section-name {
  margin: 0;
  font-size: $header-font-size;
  font-weight: $header-font-weight;
}

.title {
  display: inline-block;
  color: $text-color;
  font-size: $header-font-size;
  font-weight: $header-font-weight;
  margin: 0;
}

.page_title {
  display: flex;
  align-content: center;
  align-items: center;
  height: 100%;
}

.key-value-pairs {
  list-style: none;
  display: flex;
  margin: 0;
  height: 100%;
  padding: 0;
  align-items: center;

  &::before {
    content: "|";
    color: $line-color;
    margin: 0 10px;
    font-size: 30px;
    height: 100%;
  }

  .key-value-pair {
    height: 100%;
    margin-right: 10px;
    overflow-y: hidden;

    .key {
      font-size: 0.625rem;
      margin-right: 10px;
    }

    .value {
      display: flex;
      font-weight: 600;
      font-size: 15px;

      @include icon-after($fa-var-angle-double-right, $color: $icon-color, $margin: 3px 5px 0 10px);
    }
  }

  .key-value-pair:last-of-type {
    .value::after {
      content: "";
    }
  }
}

.help-text-wrapper i {
  color: $go-primary;
}
